.constripe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f0f2f5;
}

.constripe-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.constripe-logo, .homerunns-logo {
  width: 400px;
  max-width: 100%;
  margin: 0 auto 20px;
  display: block;
}

.stripe-logo {
  width: 150px;
  max-width: 100%;
  margin: 0 auto 20px;
  display: block;
}

.constripe-box h1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 32px;
}

.header-underline {
  width: 100%;
  height: 1px;
  background-color: white;
  margin-bottom: 20px;
}

.stripe-section, .homerunns-section {
  margin-bottom: 30px;
}

.stripe-section p, .homerunns-section p {
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
}

.stripe-onboarding-button {
  background-color: #635bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  transition: background-color 0.3s;
}

.stripe-onboarding-button:hover {
  background-color: #4b44c7;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 20px;
}

.back-to-showcase-button,
.submit-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.back-to-showcase-button {
  background-color: #6c757d;
  color: white;
}

.submit-button {
  background-color: #1e90ff;
  color: white;
}

.back-to-showcase-button:hover,
.submit-button:hover {
  opacity: 0.9;
}

.spacer {
  flex-grow: 1;
}

.skip-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.skip-link:hover {
  text-decoration: underline;
}
