.converify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.converify-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 500px;
  max-width: 90%;
  text-align: center;
  color: white;
  position: relative;
}

.converify-logo {
  width: 60%;
  max-width: 300px;
  margin: 0 auto 20px;
  display: block;
}

.converify-box h1 {
  margin-bottom: 20px;
  font-size: 28px;
}

.converify-box p {
  margin-bottom: 15px;
  font-size: 16px;
}

.verify-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 300px;
}

.verify-button:hover {
  background-color: #0056b3;
}

.back-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
  background-color: white;
  color: #080350;
}
