.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.signup-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 500px;
  height: 500px; /* Changed to match width for a square shape */
  text-align: center;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signup-logo {
  width: 70%;
  max-width: 350px;
  margin: 0 auto 20px; /* Center the logo and add some bottom margin */
  display: block; /* Ensure it's a block element for margin auto to work */
}

.signup-box h1 {
  color: white;
  margin-bottom: 15px;
  font-size: 24px; /* Slightly reduced font size */
}

.stylized-text {
  color: #e0e0e0;
  font-size: 18px;
  margin-bottom: 20px;
  font-style: italic;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.signup-button {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: opacity 0.3s;
}

.signup-button.homeowner {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.signup-button.contractor {
  background-color: #1e90ff;
  color: white;
}

.signup-button.contractor:hover {
  opacity: 0.9;
}

.login-link {
  color: #e0e0e0;
  font-size: 16px;
  margin-top: 10px;
}

.login-link a {
  color: #1e90ff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}
