.conbio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f0f2f5;
  position: relative;
}

.conbio-box {
  background-color: #080350;
  padding: 10px 40px 40px; /* Further reduced top padding */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  height: 92vh; /* Increased from 90vh to 92vh */
  display: flex;
  flex-direction: column;
  color: white;
}

.conbio-logo {
  width: 400px;
  max-width: 100%;
  margin: 0 auto 5px; /* Further reduced bottom margin */
  display: block;
}

.conbio-box h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  position: relative;
  padding-bottom: 20px;
}

.conbio-box h1:first-of-type {
  margin-top: 5px; /* Reduced top margin */
  margin-bottom: 5px; /* Reduced bottom margin */
  padding-bottom: 10px; /* Reduced padding */
}

.conbio-box h1:first-of-type::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: white;
}

.conbio-box h1:nth-of-type(2) {
  margin-top: 30px;
  margin-bottom: 5px; /* Reduced space between heading and underline */
  padding-bottom: 0; /* Remove padding for the second h1 */
}

.header-underline {
  width: 100%;
  height: 1px; /* Changed back to 1px thickness */
  background-color: white;
  margin-bottom: 15px; /* Reduced space below the line */
}

.conbio-box form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reduced gap between form elements */
  flex: 1;
  margin-bottom: 2px; /* Reduce bottom margin of the form */
}

.conbio-box input {
  width: 100%;
  padding: 12px; /* Reduced from 15px */
  border: none;
  border-radius: 6px;
  font-size: 16px; /* Reduced from 18px */
  background-color: white;
  color: #333;
}

.conbio-box input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #1e90ff;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 5px;
  margin-bottom: 0; /* Remove bottom margin */
}

.back-to-services-button,
.submit-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.back-to-services-button {
  background-color: #6c757d;
  color: white;
  margin-right: auto; /* Push this button to the left */
}

.submit-button {
  background-color: #1e90ff;
  color: white;
}

.back-to-services-button:hover,
.submit-button:hover {
  opacity: 0.9;
}

.bottom-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.back-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 0;
  transition: opacity 0.3s;
}

.back-button:hover {
  opacity: 0.8;
}

.skip-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-top: 0; /* Remove top margin */
  padding-top: 2px; /* Add a tiny bit of padding instead */
  display: block;
  text-align: center;
}

.skip-link:hover {
  text-decoration: underline;
}

.form-columns {
  display: flex;
  gap: 30px; /* Reduced from 40px */
  margin-top: 5px; /* Reduced top margin */
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reduced from 20px */
}

.photo-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Changed to center */
  height: 100%;
  border: 2px dashed #ffffff;
  border-radius: 10px;
  padding: 15px; /* Reduced from 20px */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.photo-upload-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.photo-upload-container h3 {
  margin-top: 0;
  margin-bottom: 15px; /* Reduced from 20px */
  font-size: 22px; /* Reduced from 24px */
}

.photo-placeholder {
  width: 250px; /* Reduced from 300px */
  height: 250px; /* Reduced from 300px */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
  margin-bottom: 20px;
}

.photo-placeholder .user-icon {
  font-size: 125px; /* Reduced from 150px */
  color: rgba(255, 255, 255, 0.5);
}

.photo-placeholder .camera-icon {
  position: absolute;
  bottom: 25px; /* Adjusted from 30px */
  right: 25px; /* Adjusted from 30px */
  font-size: 35px; /* Reduced from 40px */
  color: rgba(255, 255, 255, 0.8);
  background-color: #1e90ff;
  padding: 10px; /* Reduced from 12px */
  border-radius: 50%;
}

.photo-upload-input {
  display: none;
}

.photo-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; /* Ensure the image stays circular */
}

.conbio-box h1:nth-of-type(2) {
  margin-top: 30px;
  margin-bottom: 5px; /* Reduced space between heading and underline */
}

.company-introduction {
  width: 100%;
  padding: 12px; /* Reduced from 15px */
  border: none;
  border-radius: 6px;
  font-size: 16px; /* Reduced from 18px */
  font-family: inherit;
  background-color: white;
  color: #333;
  resize: vertical;
  min-height: 80px; /* Reduced from 100px */
  margin-top: 5px; /* Reduced space between underline and text box */
}

.company-introduction:focus {
  outline: none;
  box-shadow: 0 0 0 2px #1e90ff;
}

.field-label {
  font-size: 12px; /* Reduced from 14px */
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: white;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Add these new styles */
.photo-remove-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 0, 0, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
  transform: translate(20%, -20%); /* Adjust this to position the button on the edge */
}

.photo-remove-button:hover {
  background-color: rgba(255, 0, 0, 1);
}

.photo-remove-button::before,
.photo-remove-button::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: white;
}

.photo-remove-button::before {
  transform: rotate(45deg);
}

.photo-remove-button::after {
  transform: rotate(-45deg);
}

/* Update this existing style */
.photo-placeholder {
  /* ... existing styles ... */
  position: relative; /* Ensure this is set for absolute positioning of the remove button */
  overflow: visible;
}

/* ... rest of the existing styles ... */

.address-suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%; /* Position right below the input field */
  left: 0;
  right: 0; /* This will make it match the width of the parent .form-field */
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-bottom-left-radius: 6px; /* Match the input field's border radius */
  border-bottom-right-radius: 6px;
}

.address-suggestions li {
  padding: 10px;
  cursor: pointer;
  color: #333;
  border-bottom: 1px solid #eee; /* Add a subtle separator between items */
}

.address-suggestions li:last-child {
  border-bottom: none; /* Remove border from last item */
}

.address-suggestions li:hover {
  background-color: #f0f0f0;
}

.form-field {
  position: relative; /* Add this to contain the absolute positioning of suggestions */
}
