.contractor-signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.contractor-signup-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 600px;
  height: 600px;
  text-align: center;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contractor-signup-logo {
  width: 60%;
  max-width: 300px;
  margin: 0 auto 20px;
  display: block;
}

.contractor-signup-box h1 {
  color: white;
  margin-bottom: 20px;
  font-size: 28px;
}

.contractor-signup-box form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contractor-signup-box input {
  width: 80%;
  padding: 12px;
  margin-bottom: 15px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  box-sizing: border-box;
}

.contractor-signup-box input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #1e90ff;
}

.signup-submit-button {
  width: 80%;
  padding: 12px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: opacity 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.signup-submit-button:hover {
  opacity: 0.9;
}

.login-link {
  color: #e0e0e0;
  font-size: 16px;
  margin-top: 20px;
}

.login-link a {
  color: #1e90ff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.error-message {
  color: #ff4d4d;
  margin-bottom: 10px;
  font-weight: bold;
}

.user-details {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.user-details h2 {
  color: white;
  margin-top: 0;
}

.user-details pre {
  color: #e0e0e0;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.delete-user-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.delete-user-button:hover {
  opacity: 0.9;
}
