.conservices-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f0f2f5;
}

.conservices-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.conservices-logo {
  width: 400px;
  max-width: 100%;
  margin: 0 auto 20px;
  display: block;
}

.conservices-box h1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 32px;
}

.header-underline {
  width: 100%;
  height: 1px;
  background-color: white;
  margin-bottom: 20px;
}

.services-list {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  overflow-y: auto;
  padding: 0 20px;
  flex: 1;
}

.services-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 30%;
}

.service-button {
  width: 100%;
  padding: 15px;
  background-color: white;
  color: #080350;
  border: 2px solid #080350;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.service-button:hover {
  background-color: #f0f0f0;
}

.service-button.selected {
  background-color: #0c32A4;
  color: white;
  border-color: #0c32A4;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 20px;
}

.spacer {
  width: 200px; /* Same width as the submit button */
}

.submit-button {
  width: 200px;
  padding: 12px 24px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.back-button,
.submit-button {
  width: 200px;
  padding: 12px 24px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.back-button {
  visibility: hidden; /* Hide the back button on this page */
}

.skip-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.skip-link:hover {
  text-decoration: underline;
}

.logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
