.concomplete-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f0f2f5;
}

.concomplete-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}

.concomplete-logo {
  align-self: center;
  width: 400px;
  max-width: 100%;
  margin-bottom: 20px;
}

.concomplete-box h1 {
  font-size: 36px;
  margin-bottom: 10px;
  margin-top: 0;
}

.header-underline {
  width: 100%;
  height: 1px;
  background-color: white;
  margin-bottom: 20px;
}

.concomplete-box h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vendor-info,
.stripe-account,
.stripe-requirements {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.vendor-info pre,
.stripe-account pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  font-size: 14px;
  color: #ffd700;
}

.stripe-requirements h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffd700;
}

.stripe-requirements ul {
  list-style-type: none;
  padding-left: 0;
}

.stripe-requirements li {
  font-size: 14px;
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
}

.stripe-requirements li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #ffd700;
}

.app-store-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
}

.app-store-badge,
.play-store-badge {
  height: 80px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.app-store-badge:hover,
.play-store-badge:hover {
  opacity: 0.8;
}

.button-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.back-to-stripe-button,
.continue-to-stripe-button {
  padding: 12px 24px;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.back-to-stripe-button {
  background-color: #6c757d;
}

.back-to-stripe-button:hover {
  background-color: #5a6268;
}

.continue-to-stripe-button {
  padding: 12px 24px;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
  background-color: #1e90ff;
  margin-top: auto;
  align-self: center;
}

.continue-to-stripe-button:hover {
  background-color: #1a7ae0;
}

.vendor-messages,
.stripe-requirements {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.vendor-messages h2,
.stripe-requirements h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ffd700;
}

.vendor-messages ul,
.stripe-requirements ul {
  list-style-type: none;
  padding-left: 0;
}

.vendor-messages li,
.stripe-requirements li {
  font-size: 14px;
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
}

.vendor-messages li::before,
.stripe-requirements li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #ffd700;
}

.subheader-text {
  font-size: 16px;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 20px;
}

.profile-requirements h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ffd700;
}

.profile-requirements ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

.profile-requirements li {
  margin-bottom: 10px;
}

.requirement-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #1e90ff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  border: 2px solid #1e90ff;
  margin-bottom: 10px;
  width: auto;
  min-width: 250px;
  max-width: 400px;
  text-align: center;
}

.requirement-button:hover {
  background-color: #ffffff;
  color: #1e90ff;
  box-shadow: 0 4px 8px rgba(30, 144, 255, 0.3);
  transform: translateY(-2px);
}

.stripe-requirement {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.toggle-requirements {
  margin-top: 10px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
  max-width: 400px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  text-decoration: underline;
}

.toggle-requirements:hover {
  color: #ffd700;
}

.stripe-requirements-container {
  margin-top: 15px;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
}

.stripe-requirements-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
  color: white;
  text-align: left;
}

.stripe-requirement-item {
  font-size: 18px;
  margin: 10px 0;
  text-align: left;
}

.info-icon,
.stripe-requirements-popout {
  display: none;
}

.success-message {
  margin-bottom: 30px;
  color: #ffffff;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.verification-text {
  font-size: 28px;
  line-height: 1.5;
  margin-top: 40px;
  margin-bottom: 40px;
}

.download-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.download-text {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #080350;
  padding: 20px;
  border-radius: 12px;
  max-width: 300px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-title {
  font-size: 18px;
  color: white;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  text-align: center;
}

.stripe-requirements-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 20px 0;
  color: white;
  text-align: left;
}

.stripe-requirement-item {
  font-size: 14px;
  margin: 8px 0;
  text-align: left;
}

.close-modal-x {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-modal-x:hover {
  color: #1e90ff;
}
