.conshowcase-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f0f2f5;
}

.conshowcase-box {
  background-color: #080350;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: 1200px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.conshowcase-logo {
  width: 400px;
  max-width: 100%;
  margin: 0 auto 20px;
  display: block;
}

.conshowcase-box h1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 32px;
}

.conshowcase-box h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 16px;
}

.header-underline {
  width: 100%;
  height: 1px;
  background-color: white;
  margin-bottom: 20px;
}

.photo-upload-area {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px; /* Increased margin to add more space */
  justify-content: flex-start; /* Changed to flex-start to left-align */
}

.photo-upload-label {
  width: 200px;
  height: 200px;
  border: 2px dashed #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-icon {
  font-size: 50px;
  color: #ffffff;
}

.photo-upload-input {
  display: none;
}

.photo-container {
  position: relative;
  width: 200px;
  height: 200px;
  border: 2px dashed #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.uploaded-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-photo-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-photo-button:hover {
  background-color: rgba(255, 0, 0, 0.8);
}

.remove-photo-button svg {
  color: white;
  font-size: 16px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 20px;
}

.back-to-details-button,
.submit-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.back-to-details-button {
  background-color: #6c757d;
  color: white;
}

.submit-button {
  background-color: #1e90ff;
  color: white;
}

.back-to-details-button:hover,
.submit-button:hover {
  opacity: 0.9;
}

.spacer {
  flex-grow: 1;
}

.skip-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.skip-link:hover {
  text-decoration: underline;
}

.coverage-table {
  width: 100%;
  margin-top: 10px; /* Reduced space between underline and table */
  margin-bottom: 20px;
}

.coverage-table td {
  padding: 10px 0;
}

.coverage-table .question {
  font-size: 20px;
  font-weight: bold;
  padding-right: 20px;
}

.coverage-table select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

/* Ensure the form takes up available space */
.conshowcase-box form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Push the button container to the bottom */
.conshowcase-box {
  display: flex;
  flex-direction: column;
}

/* Add more padding above the "Reassure Homeowners" section */
.conshowcase-box h1:nth-of-type(2) {
  margin-top: 30px;
  margin-bottom: 10px; /* Reduced space between heading and underline */
}
